
<template>
  <div class="all pt80">
    <div class="title">
      <span></span>
      <span>空气质量平均情况</span>
      <span>{{ename}}</span>
    </div>
    <div id="cont"></div>
    <!-- <div class="midu"> -->
      <!-- <div class="tvoc">
        <span>TVOC</span>
        <span
          :class="{bule:list.tvo<=0.3,yellow:list.tvo>0.3&&list.tvo<0.5,red:list.tvo>=0.5}">{{list.tvo>=0.6? 0.6:list.tvo}}</span>
        <span :class="{bule:list.tvo<=0.3,yellow:list.tvo>0.3&&list.tvo<0.5,red:list.tvo>=0.5}">mg/m³</span>
        <div class="status">
          <i v-show="list.tvo>0.3"></i>
          <i v-show="list.tvo<=0.3"></i>
          <i v-show="list.tvo<=0.3||list.tvo>=0.5"></i>
          <i v-show="list.tvo>0.3&&list.tvo<0.5"></i>
          <i v-show="list.tvo<0.5"></i>
          <i v-show="list.tvo>=0.5"></i>
        </div>
      </div> -->
      <!-- <div class="tvoc">
        <span>甲醛</span>
        <span
          :class="{bule:list.forma<0.08,yellow:list.forma>=0.08&&list.forma<0.2,red:list.forma>=0.2}">{{list.forma}}</span>
        <span :class="{bule:list.forma<0.08,yellow:list.forma>=0.08&&list.forma<0.2,red:list.forma>=0.2}">mg/m³</span>
        <div class="status"></div>
        <span :class="{bule:jiaquan<0.08,yellow:jiaquan>=0.08&&jiaquan<0.2,red:jiaquan>=0.2}">{{jiaquan}}</span>
            <span :class="{bule:jiaquan<0.08,yellow:jiaquan>=0.08&&jiaquan<0.2,red:jiaquan>=0.2}">mg/m³</span>
        <div class="status">
          <i v-show="list.forma>=0.08"></i>
          <i v-show="list.forma<0.08"></i>
          <i v-show="list.forma<0.08||list.forma>=0.2"></i>
          <i v-show="list.forma>=0.08&&list.forma<0.2"></i>
          <i v-show="list.forma<0.2"></i>
          <i v-show="list.forma>=0.2"></i>
        </div>
      </div> -->
    <!-- </div> -->
  </div>

</template>

<script>
import * as echarts from 'echarts';
import { airAvg } from '@/api/aerosol'
export default {
  data () {
    return {
      ename: 'Average air quality'.toUpperCase(),
      list: {},
      jiaquan: null,
      timer1: null,

    }
  },
  created () {
    this.airAvg()
    // this.jiaquan=(Math.random()*(0.07-0.01)+0.01).toFixed(2)
    this.timer1 = setInterval(() => {
      this.airAvg();
      // this.jiaquan=(Math.random()*(0.07-0.01)+0.01).toFixed(2)
    }, 180000);
  },

  methods: {
    // 获取空气质量平均情况的数据
    async airAvg () {
      let { data } = await airAvg()
      this.list = data
      this.$nextTick(() => {
        this.getAverage()
      })
    },
    getAverage () {
      let myChart = echarts.init(document.getElementById('cont'));
      let option = {
        tooltip: {
          formatter: '{a} <br/>{b} : {c}%'
        },
        series: [
          {
            name: 'Quality',
            type: 'gauge',
            radius: '90%',

            detail: {
              formatter: '{value}',
              color: '#fff',
              fontWeight: 600,
              fontSize: 60,
              fontFamily: 'DS-Digital-BoldItalic, DS-Digital',
              offsetCenter: ['0', '-10%']
            },
            data: [
              {
                value: +this.list.tem,
                name: '室内温度℃'
              }
            ],
            endAngle: -15,
            colorBy: 'data',
            center: ['23%', '51%'],
            startAngle: 195,
            min: -10,
            max: 50,
            splitLine: {
              show: false
            },
            axisLine: {
              show: true,
              roundCap: true,

              lineStyle: {
                color: [
                  [
                    1,
                    new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: 'rgba(145, 190, 109, 1)'
                      },
                      {
                        offset: 0.43,
                        color: 'rgba(249, 182, 78, .85)'
                      },
                      {
                        offset: 0.85,
                        color: 'rgba(249, 182, 78, 1)'
                      },
                      {
                        offset: 1,
                        color: 'rgba(249, 65, 68, 1)'
                      }
                    ])
                  ]
                ],
                width: 8,

              }
            },

            // 刻度样式
            axisTick: {
              show: false,
              splitNumber: 5,
              lineStyle: {
                color: '#ffffff'
              }
            },
            // 刻度标签
            axisLabel: {
              show: false,
              distance: 25,
              formatter: function (value) {
                if (value % 20 === 0) return value + '%';
              },
              color: '#ffffff',
              fontFamily: 'DS-Digital-BoldItalic, DS-Digital',
              fontSize: 10
            },
            // 指针
            pointer: {
              icon: 'circle',
              width: 15,
              offsetCenter: [0, '-64%'],
              itemStyle: {
                color: 'rgba(255, 255, 255, 1)',
                borderWidth: 1,
                borderColor: 'rgba(0,0,0,.5)'
              }

            },
            title: {
              fontSize: 25,
              offsetCenter: ['0', '70%'],
              color: 'rgba(255, 255, 255, 1)',
              fontWeight: 400
            }
          },
          {
            name: 'Quality',
            type: 'gauge',
            radius: '90%',

            detail: {
              formatter: '{value}',
              color: '#fff',
              fontWeight: 600,
              fontSize: 60,
              fontFamily: 'DS-Digital-BoldItalic, DS-Digital',
              offsetCenter: ['0', '-10%']
            },
            data: [
              {
                value: +this.list.hum,
                name: '室内湿度%'
              }
            ],
            endAngle: -15,
            colorBy: 'data',
            center: ['74%', '51%'],
            startAngle: 195,
            min: 0,
            max: 100,
            splitLine: {
              show: false
            },
            axisLine: {
              show: true,
              roundCap: true,

              lineStyle: {
                color: [
                  [
                    1,
                    new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: 'rgba(249, 65, 68, 1)'
                      },
                      {
                        offset: 0.1,
                        color: 'rgba(249, 199, 80, 1)'
                      },
                      {
                        offset: 0.3,
                        color: 'rgba(145, 190, 109, 1)'
                      },
                      {
                        offset: 0.7,
                        color: 'rgba(145, 190, 109, 1)'
                      },
                      {
                        offset: 0.9,
                        color: 'rgba(249, 199, 80, 1)'
                      },

                      {
                        offset: 1,
                        color: 'rgba(249, 65, 68, 1)'
                      }
                    ])
                  ]
                ],
                width: 8,

              }
            },

            // 刻度样式
            axisTick: {
              show: false,
              splitNumber: 5,
              lineStyle: {
                color: '#ffffff'
              }
            },
            // 刻度标签
            axisLabel: {
              show: false,
              distance: 25,
              formatter: function (value) {
                if (value % 20 === 0) return value + '%';
              },
              color: '#ffffff',
              fontFamily: 'Arial',
              fontSize: 10
            },
            // 指针
            pointer: {
              icon: 'circle',
              width: 15,
              offsetCenter: [0, '-63%'],
              itemStyle: {
                color: 'rgba(255, 255, 255, 1)',
                borderWidth: 1,
                borderColor: 'rgba(0,0,0,.5)'
              }

            },
            title: {
              fontSize: 25,
              offsetCenter: ['0', '70%'],
              color: 'rgba(255, 255, 255, 1)',
              fontWeight: 400
            }
          }
        ]
      }

      option && myChart.setOption(option);
      let that = this
      window.addEventListener('resize', function () {
        that.myChart.resize()
      })

    }
  },
  beforeUnmount () {
    clearInterval(this.timer1)
  }

}
</script>

<style lang="scss" scoped>
.title {
  margin-top: -15px;
  margin-bottom: 20px;
  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  span:nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    margin-right: 8px;
    margin-left: 4px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #08f4da;
    line-height: 22px;
  }
}
.midu {
  width: 70%;
  display: flex;
  justify-content: space-between;
  margin: 6% 0 6% 5%;

  .tvoc {
    width: 183px;
    height: 216px;
    background: url("~@/assets/img/5.0/编组 18.png") no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    span:nth-child(1) {
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-size: 20px;
    }
    span:nth-child(2) {
      font-size: 70px;
      font-family: DS-Digital-BoldItalic, DS-Digital;
      font-weight: 800;
    }
    span:nth-child(3) {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: normal;
    }
    .bule {
      color: #08f4da;
    }
    .yellow {
      color: #f6c751;
    }
    .red {
      color: #e02020;
    }
    .status {
      text-align: center;
      display: flex;
      justify-content: space-between;
      i {
        width: 27px;
        height: 6px;
        margin: 4px;
        display: inline-block;
      }
      i:nth-child(1),
      i:nth-child(3),
      i:nth-child(5) {
        background: url("~@/assets/img/5.0/矩形备份 21.png") no-repeat;
        background-size: contain;
      }
      i:nth-child(2) {
        background: url("~@/assets/img/5.0/矩形备份 22.png") no-repeat;
        background-size: contain;
      }
      i:nth-child(4) {
        background: url("~@/assets/img/5.0/矩形备份 13.png") no-repeat;
        background-size: contain;
      }
      i:nth-child(6) {
        background: url("~@/assets/img/5.0/矩形备份 5.png") no-repeat;
        background-size: contain;
      }
    }
  }
}
#cont {
  width: 567px;
  height: 190px;
}
</style>