<template>

  <!-- <el-carousel>
    <el-carousel-item v-for="item in 2" :key="item">
     
    </el-carousel-item>
  </el-carousel> -->
  <!-- 水球图 -->
  <div class="mainBox" ref="dom"></div>

</template>

<script>
import * as echarts from 'echarts';
import 'echarts-liquidfill'
export default {
  name: 'shuiqiu2',
  props: ['info', 'titleFontSize','shuiqiuH'],
  data () {
    return {
      Percent: [],
      time: '',
      myChart: null,
      timerDom: null
    };
  },

  components: {},

  created () { },

  mounted () {

    this.setDate()
    //清除定时器
    clearInterval(this.timer)
    this.timerDom = null
    this.timer = setInterval(() => {
      this.setDate()
    }, 10100)
  },
  beforeUnmount () {
    //清除定时器
    clearInterval(this.timer)
    this.timerDom = null
  },
  methods: {
    setDate () {
      // this.myChart = null
      if (this.info.time == 1) {
        this.Percent = ['0', '0']
        this.time = '0'
      } else {
        if (this.info.format / 3600 > 24) {
          this.Percent = ['1', '1']
          this.time = '>24'
        } else {
          this.Percent = [(this.info.format / 3600 / 24).toFixed(2), (this.info.format / 3600 / 24).toFixed(2)]
          this.time = (this.info.format / 3600).toFixed(2)
        }
      }
      this.$nextTick(() => {
        this.getDom()
      })
    },
    getDom () {
      this.myChart = echarts.init(this.$refs.dom)

      // console.log('字体大小',titleFontSize);
      var option = {
        series: [{
          //水的颜色
          color: ['#059584'],
          type: 'liquidFill',
          data: this.Percent,
          // center: ['10%', '50%'],
          //球的大小
          radius: '95%',
          //圆环的颜色
          outline: {
            show: true,
            itemStyle: {
              color: 'none',
              borderColor: '#059584',
              borderWidth: 2,
            }
          },
          //背景颜色取消
          backgroundStyle: {
            borderColor: 'none',
            color: 'none'
          },
          //中间文字样式
          label: {
            formatter: (item) => {
              return `${this.time}h` + '\n' + `${this.info.time == 1 ? '未运行' : '运行中'}`
            },
            fontSize: this.titleFontSize,
            fontWeight: 500,
            lineHeight: this.shuiqiuH,
            fontFamily: 'PingFangSC-Medium, PingFang SC',
            color: '#fff'
          }
        }]
      };
      this.myChart.setOption(option)
    }
  }
}
</script>
<style lang='scss' scoped>
.mainBox {
  // width: 628px;
  // height: 235px;
  width: 100px;
  height: 100px;
}
.name_box {
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
</style>
