<template>
  <div>
    <div class="title pt20">
      <span></span>
      <span>设备运行情况</span>
      <span>{{ename}}</span>
    </div>

    <div class="content">
      <p>运行中设备数：<span>{{running}}台</span></p>
      <el-progress :percentage="percentage3" stroke-width="6"></el-progress>
      <p>未运行设备数：<span>{{other}}台</span></p>
      <el-progress :percentage="percentage4" stroke-width="6"></el-progress>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    //设备总数
    total: null,
    //运行中
    running: null,
    percentage3: null,
    //未运行
    other: null,
    percentage4: null,
  },
  data () {
    return {
      ename: 'Equipment operation'.toUpperCase(),

    }
  },
 
}
</script>

<style lang="scss" scoped>
.title {
  // z-index: 999;
  // margin-top: -15px;
  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  span:nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    margin-right: 8px;
    margin-left: 4px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #08f4da;
    line-height: 22px;
  }
}
.content {
  width: 80%;
  margin-top: 41px;
  background: rgba(0, 0, 0, 0);
  p:nth-child(1) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 22px;
    margin-bottom: 13px;
    span {
      font-size: 18px;
      font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
      font-weight: normal;
      color: #f5da65;
      line-height: 25px;
    }
  }
  ::v-deep .el-progress:nth-child(2),
  .el-progress:nth-child(4) {
    margin-bottom: 40px;
    .el-progress-bar__inner {
      background: linear-gradient(270deg, #f7da65 0%, #07ba74 99%);
    }
    .el-progress-bar__outer {
      background: rgba(255, 255, 255, 0.15);
    }
    .el-progress__text {
      position: relative;
      bottom: 35px;

      right: 10%;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 25px;
    }
  }
  ::v-deep .el-progress:nth-child(4) {
    margin-bottom: 40px;
    .el-progress-bar__inner {
      background: linear-gradient(270deg, #d1d1d1 0%, #5b5b5b 100%);
    }
    .el-progress-bar__outer {
      background: rgba(255, 255, 255, 0.15);
    }
    .el-progress__text {
      position: relative;
      bottom: 35px;
      right: 10%;
      width: 40px;

      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 25px;
    }
  }
  p:nth-child(3) {
    margin-bottom: 13px;

    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 22px;
    span {
      width: 44px;
      height: 25px;
      font-size: 18px;
      font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
      font-weight: normal;
      color: #ffffff;
      line-height: 25px;
    }
  }
}
</style>