<template>
  <div class="all">
    <div class="title">
      <span></span>
      <span>今日保护时长</span>
      <span>{{ename}}</span>
    </div>
    <div class="content">
      <div class="one">
        <span>{{list5?.greenNum}}<span>h</span></span>
        <div class="img"><span>等离子体消毒机</span> </div>
      </div>
      <div class="one">
        <span>{{list5?.yellowNum}}<span>h</span></span>
        <div class="img"><span>水离子消毒机</span> </div>
      </div>
      <div class="one">
        <span>{{list5?.redNum}}<span>h</span></span>
        <div class="img"><span>今日累计保护时长</span> </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list5: Object
  },
  data () {
    return {
      ename: 'Protection duration today'.toUpperCase(),
      //数据
    }
  },
  created () {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.all {
  padding-left: 5%;
  width: 90%;
  margin-top: 50px;
}
.title {
  margin-top: 30px;
  margin-bottom: 6px;

  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  span:nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    margin-right: 8px;
    margin-left: 4px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #08f4da;
    line-height: 22px;
  }
}
.content {
  width: 110%;
  margin-left: 2%;
  display: flex;
  justify-content: space-evenly;
  background: url("../../../assets/img/切图4.0/编组 11.png") no-repeat;
  background-size: 90%;
  padding-right: 6%;
  height: 230px;
}
.one {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 50px;
  span:nth-child(1) {
    font-size: 48px;
    font-family: DS-Digital-BoldItalic, DS-Digital;
    font-weight: normal;
    color: #ffc53b;
    line-height: 48px;
    margin-top: 20px;
    span {
      position: relative;
      bottom: 14%;
      left: 2%;
      font-size: 17px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: #ffc53b;
    }
  }
  .img {
    background: url("~@/assets/img/切图4.0/编组 16备份.png") no-repeat;
    background-size: 100%;
    height: 130px;
    margin-top: -25px;
    span {
      line-height: 100px;
      font-size: 16px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: #ffffff;
      position: relative;
      top: 75px;
    }
  }
}
</style>