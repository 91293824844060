<template>
  <div>
    <div class="all">
      <div class="content" :style="{'--uploadStyle':length+'s','--animation':animation}">
        <div v-for="item in list" :key="item">
          <p>{{item?.deviceName}} </p>
          <el-progress stroke-width="6" :percentage="item.percentage"></el-progress>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
export default {
  props: {
    //数据
    list: [],

    //数据的长度
    length: Number,
    //动画的停止与否
    animation: String
  },
  data () {
    return {

    }
  },
  created () {


  },
  methods: {


  }
}

</script>

<style lang="scss" scoped>
.all {
  margin-left: 3%;
}
.content:hover {
  animation-play-state: paused;
}
.content {
  animation: move var(--uploadStyle) linear infinite;
  animation-play-state: var(--animation);
  width: 90%;
  background: rgba(0, 0, 0, 0);
  padding-left: 25%;
  p {
    position: relative;
    top: 24px;
    left: -33%;
    width: 135px;
    height: 22px;
    font-size: 16px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    line-height: 22px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
  ::v-deep .el-progress-bar__inner {
    background: linear-gradient(270deg, #9565f7 0%, #0757ba 100%);
  }
  ::v-deep .el-progress-bar__outer {
    background: rgba(255, 255, 255, 0.2);
    margin-right: 20%;
    margin-left: 10%;
  }
  ::v-deep .el-progress__text {
    width: 40px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 25px;
    text-align: start;
    position: relative;
    right: 2%;
  }
}
/* 通过CSS3动画滚动marquee */
@keyframes move {
  0% {
  }

  100% {
    transform: translateY(-50%);
  }
}
</style>