<template>
  <div>
    <top :shouback="true" />
    <div class="container flex">
      <div class="box_l flex1 h-max pt37 color-f">
        <!-- 设备类型 -->
        <deviceType :list1="list1" />
        <!-- 每台累计消毒时长 -->
        <!-- 实时保护情况  -->
        <div class="title">
          <span></span>
          <span>实时保护情况</span>
          <span>REAL TIME PROTECTION</span>
        </div>
        <div class="header">
          <span></span>
          <span>设备名称</span>
          <span>持续保护时长(h)</span>
          <span>保护率(%)</span>
          <span></span>
        </div>
        <realTime class="realTime" :list="list7" :length="length1" :animation="animation1" />
        <!-- <everyTime :list2="list2" /> -->
        <!-- 设备运行情况 -->
        <running :total="total" :running="running" :percentage3="percentage3" :other="other"
          :percentage4="percentage4" />
      </div>
      <div class="box_center w488 h-max pl20">
        <!-- 中间上面的条形图 -->
        <div class="titleImg"></div>
        <!-- 累计保护时长 -->
        <!-- <CountFlop :val='totalArea' title='累计保护时长' suffix='㎡' style="margin:0 auto" class="mt93 mr20" /> -->
        <CountFlop :val="totalArea" title="累计保护时长" suffix="h" style="margin:0 auto" class="mt93 mr20" />
        <!-- 气溶胶实时数据监测 -->
        <div>
          <Aerosol v-if="AerosolIsShow.indexOf(6)>-1" />
          <display v-else />
        </div>

        <!-- —— 设备运行状态 —— -->
        <div class="hesuanTime" ref="shuiqiuBigDOm">
          <div class="hesuanTimeTitle">—— 设备运行状态 ——</div>
          <!-- <Shuiqiu3 /> -->
          <el-carousel indicator-position="none">
            <el-carousel-item v-for="(item, i) in sheBeiStateList" :key="i">
              <div class="flex text-center" v-if="showShebei">
                <div v-for="(item2, i2) in item" :key="i2" class="mr20">
                  <div class="yuan">
                    <Shuiqiu2 ref="shuiqiu2Dom" :info="item2" :titleFontSize='titleFontSize' :shuiqiuH='shuiqiuH' />
                  </div>
                  <div class="nameBox">{{ item2.name }}</div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- <div class="all">
          <div class="titles"></div>
          <div class="headers">—— 设备运行状态 ——</div>
          <el-carousel indicator-position="outside">
            <el-carousel-item v-for="item in list3" :key="item">
              <div class="content">
                <div class="one" v-for="key in item" :key="key">
                  <shuiqiu :percent="key" ref="dom" class="shuiqiu" />
                  <i :class="{wei:key.time==1}">{{key.time == 1 ? '未运行' : '运行中'}}</i>
                  <span :class="{weii:key.time==1}">{{key.name}}</span>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div> -->
        <!-- <hesuanTime :list="list3" /> -->
      </div>
      <div class="box_r flex1 h-max pl70">
        <!-- 空气质量平均情况 -->
        <averageQulity />
        <!-- 设备开机时长占比 -->
        <!-- <div class="title1">
          <span></span>
          <span>设备开机时长占比</span>
          <span>PROPORTION OF EQUIPMENT STARTUP TIME</span>
        </div>
        <div class="header1">
          <span></span>
          <span>设备名称</span>
          <span>占比率</span>
          <span></span>
        </div>
        <kaijizhanbi class="kaijizhanbi" :list="list6" :length="length" :animation="animation" /> -->
        <div class="allm pt20">
          <div class="titlem">
            <span></span>
            <span>今日消毒时长</span>
            <span>{{ ename }}</span>
          </div>
          <el-carousel indicator-position="outside">
            <el-carousel-item v-for="item in list2" :key="item">
              <div class="content">
                <div class="one" v-for="key in item" :key="key">
                  <span>{{ key.useTiem ? parseInt(key.useTiem / 3600) : 0
                    }}<i> 小时</i>
                    {{ key.useTiem ? parseInt((key.useTiem % 3600) / 60) : 0
                    }}<i> 分</i><br />
                    <span>{{ key.deviceName }}</span></span>
                  <div class="img"></div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- 设备使用记录 -->
        <div class="title2">
          <span></span>
          <span>设备使用记录</span>
          <span>EQUIPMENT USE RECORD</span>
        </div>
        <div class="header2">
          <span></span>
          <span>设备名称</span>
          <span>开启时间</span>
          <span>关闭时间</span>

          <span></span>
        </div>
        <useprogress class="useprogress" :list="list8" :length="length2" :animation="animation2" />
        <!-- 今日保护时长 -->
        <todayTime :list5="list5" />
      </div>
    </div>
  </div>
</template>

<script>
// import shuiqiu from "./components/shuiqiu.vue";
import top from "@/components/top.vue";
import deviceType from "@/views/Sterilizer/components/deviceType";
import protectTiem from "@/views/Sterilizer/components/protectTiem.vue";
// import everyTime from '@/views/Sterilizer/components/everyTime'
import running from "@/views/Sterilizer/components/running";
import display from "@/views/Sterilizer/components/display";
import realTime from "@/views/Sterilizer/components/realTime.vue";
import kaijizhanbi from "@/views/Sterilizer/components/kaijizhanbi.vue";
import useprogress from "@/views/Sterilizer/components/useprogress";
import todayTime from "@/views/Sterilizer/components/todayTime";
import averageQulity from '@/views/Sterilizer/components/averageQulity'
// import hesuanTime from '@/views/Sterilizer/components/hesuanTime'
import CountFlop from "@/components/CountFlop";
import Aerosol from '@/views/SterilizerRobot/components/Aerosol.vue'
import {
  gettotalTime,
  getoverview,
  getSingelTime,
  getStatus,
  getProtectTime,
  gettotalUseTime,
  getnowUseTime,
  getDeviceUse

} from "@/api/sterilizer";
// import clearTime from '@/mixin/clearTime.js'
import { formatDate } from "@/filters";
import Shuiqiu2 from "./components/shuiqiu2";
// import Shuiqiu3 from './components/shuiqiu3'
export default {
  name: "Sterilizer",
  // mixins: [clearTime],
  components: {
    top,
    deviceType,
    protectTiem,
    running,
    display,
    realTime,
    kaijizhanbi,
    useprogress,
    todayTime,
    CountFlop,
    Shuiqiu2,
    averageQulity,
    Aerosol
  },
  data () {
    return {
      ename: "Disinfection duration today".toUpperCase(),
      //累计消毒面积
      totalArea: "",
      //设备概览数据
      list1: [],
      //消毒时长
      list2: [],
      list3: [],
      //数据
      list4: {},
      // 等离子体占比
      percentage1: null,
      // 水离子占比

      percentage2: null,
      list5: {},
      //设备总数
      total: null,
      //运行中
      running: null,
      percentage3: null,
      //未运行
      other: null,
      percentage4: null,
      //数据
      list6: [],
      //数据的长度
      length: null,
      //动画的停止与否
      animation: "",
      list7: [],
      //数据的长度
      length1: null,
      //动画的停止与否
      animation1: "",
      list8: [],
      //数据的长度
      length2: null,
      //动画的停止与否
      animation2: "",
      timer: null,
      sheBeiStateList: [], //设备运行状态列表
      showShebei: false,
      timer1: null,
      //水球图字体大小
      titleFontSize: 22,
      shuiqiuH: 40,
      AerosolIsShow: JSON.parse(sessionStorage.getItem('lookType')),//是否显示气溶胶页面

    };
  },

  created () {
    // //获取累计保护时长
    // this.gettotalTime()

    // //获取设备概览
    // this.getoverview()
    // //获取每台累计消毒时长
    // this.getSingelTime()

    // this.getStatus()
    // this.getProtectTime()
    // this.gettotalUseTime()
    // this.getnowUseTime()
    // this.getDeviceUse()
    this.getDataSources();
    this.timer = setInterval(() => {
      this.getDataSources();
    }, 180000);
  },
  mounted () {
    this.titleFontSize = this.$refs.shuiqiuBigDOm.offsetWidth / 100 * 3.2
    this.shuiqiuH = this.$refs.shuiqiuBigDOm.offsetWidth / 100 * 5.9
    this.getState1();
    this.timer1 = setInterval(() => {
      this.getState1();
    }, 180000);
  },
  beforeUnmount () {
    //清除定时器
    clearInterval(this.timer);
    clearInterval(this.timer1);
    this.timer = null
    this.timer1 = null;
  },
  methods: {
    async getState1 () {
      // this.showShebei = false
      const res = await getStatus();
      this.sheBeiStateList = res.data;
      this.showShebei = true;
      this.$nextTick(() => {
        // console.log('shuiqiu2Dom', this.$refs.shuiqiu2Dom)
        if (this.$refs.shuiqiu2Dom) {
          this.$refs.shuiqiu2Dom.setDate();
        }
      });
    },
    getDataSources () {
      getDeviceUse().then(res => {
        this.getDeviceUse(res.data);
      })
      getnowUseTime().then(res => {
        this.getnowUseTime(res.data);
      })
      gettotalUseTime().then(res => {
        this.gettotalUseTime(res.data);
      })
      gettotalTime().then(res => {
        this.gettotalTime(res.data);
      })
      getoverview().then(res => {
        this.getoverview(res.data);
      })
      getSingelTime().then(res => {
        this.list2 = res.data;
      })
      getStatus().then(res => {
        this.list3 = res.data;
      })
      getProtectTime().then(res => {
        this.getProtectTime(res.data);
      })
      // return
      //之前前端做的并发,一个请求失败就不会返回,重大bug不要用
      // const dataSources = await Promise.all([
      //   getDeviceUse(),
      //   getnowUseTime(),
      //   gettotalUseTime(),
      //   gettotalTime(),
      //   getoverview(),
      //   getSingelTime(),
      //   getStatus(),
      //   getProtectTime(),
      // ]);
      // console.log(dataSources);
      // this.getDeviceUse(dataSources[0].data);

      // this.gettotalUseTime(dataSources[2].data);
      // this.gettotalTime(dataSources[3].data);
      // this.getoverview(dataSources[4].data);

      // this.list2 = dataSources[5].data;
      // this.list3 = dataSources[6].data;
      // this.getProtectTime(dataSources[7].data);
      // clearTimeout(this.timer);
      // this.reloading();
    },
    getDeviceUse (data) {
      let arr = data.map((item) => ({
        ...item,
        startTime: item.startTime
          ? formatDate(item.startTime * 1000)
          : "--:--:--",
        finishTime: item.finishTime
          ? formatDate(item.finishTime * 1000)
          : "--:--:--",
        workingTime: item.workingTime
          ? parseInt(item.workingTime / 3600) +
          "小时" +
          parseInt((item.workingTime % 3600) / 60) +
          "分钟"
          : 0,
      }));

      if (data.length < 5) {
        this.list8 = arr;
        this.length2 = this.list8.length;
        this.animation2 = "paused";
      } else {
        this.list8 = [...arr, ...arr];
        this.length2 = this.list8.length;
        this.animation2 = "running";
      }
    },
    getnowUseTime (data) {
      let arr = data.map((item) => {
        item = {
          ...item,
          percentage:
            item.greenNum / 3600 > 2
              ? "100"
              : ((item.greenNum * 100) / 3600 / 2).toFixed(1),
          greenNum: (item.greenNum / 3600).toFixed(1),
        };

        if (item.greenNum >= 2) {
          item.pro = "99.9%";
        } else if (item.greenNum == 0) {
          item.pro = "0%";
        } else {
          item.pro = ((item.greenNum * 100) / 2).toFixed(1) + "%";
        }

        return item;
      });
      // console.log(111, arr);
      if (data.length < 6) {
        this.list7 = arr;
        this.length1 = this.list7.length;
        this.animation1 = "paused";
      } else {
        this.list7 = [...arr, ...arr];
        this.length1 = this.list7.length;

        this.animation1 = "running";
      }
    },
    gettotalUseTime ({ obj }) {
      const totals = obj.reduce((total, item) => {
        return (total += item.useTiem * 1);
      }, 0);
      let arr = obj.map((item) => ({
        ...item,
        percentage: parseInt((item.useTiem / totals) * 100),
        useTiem: parseInt(item.useTiem / 3600),
      }));
      if (obj.length < 3) {
        this.list6 = arr;
        this.length = this.list6.length;
        this.animation = "paused";
      } else {
        this.list6 = [...arr, ...arr];
        this.length = this.list6.length;
        this.animation = "running";
      }
    },
    //获取累计保护时长
    gettotalTime (data) {
      let num = parseInt(data.greenNum / 3600);
      let str = String(num).padStart(7, "0");
      this.totalArea = str.slice(1, 4) + "," + str.slice(4, 7);
      data.greenNum = data.greenNum ? parseInt(data.greenNum / 3600) : "- -";
      data.yellowNum = data.yellowNum ? parseInt(data.yellowNum / 3600) : "- -";
      data.redNum = data.redNum ? parseInt(data.redNum / 3600) : "- -";
      this.percentage1 = parseInt((data.yellowNum * 100) / data.greenNum);
      this.percentage2 = parseInt((data.redNum * 100) / data.greenNum);
      this.list4 = data;
    },
    //转换千分位
    toOrderNum (num) {
      num = num.toString().padStart(7, "0");
      if (num.length < 7) {
        num = "0" + num;
        this.toOrderNum(num);
      } else {
        //加逗号
        num = num.slice(1, 4) + "," + num.slice(4, 7);
        // this.count = num.split('')
        this.totalArea = num;
      }
    },
    //获取设备概览
    getoverview (data) {
      this.list1 = data;
      this.total = data[0].greenNum + data[1].greenNum;
      this.running = data[0].yellowNum + data[1].yellowNum;
      this.other = this.total - this.running;
      this.percentage3 = parseInt((this.running * 100) / this.total);
      this.percentage4 = parseInt((this.other * 100) / this.total);
    },
    getProtectTime (data) {
      data.greenNum = parseInt(data.greenNum / 3600);
      data.yellowNum = parseInt(data.yellowNum / 3600);
      data.redNum = parseInt(data.redNum / 3600);
      this.list5 = data;
    },
    // reloading () {
    //   this.timer = setTimeout(() => {
    //     this.getDataSources();
    //   }, 180000);
    // },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 1004px;
  background: #000;
  perspective: 1800px;
  .box_l {
    padding-left: 2%;
    transform: rotateY(10deg);
    background: url("~@/assets/img/4.0/box_l.png") no-repeat;
    background-size: 100% 100%;
    max-width: 720px;
  }
  .box_r {
    transform: rotateY(-10deg);
    background: url("~@/assets/img/4.0/box_r.png") no-repeat;
    background-size: 100% 100%;
    max-width: 720px;
  }
}
.titleImg {
  width: 330px;
  height: 12px;
  background: url("../../assets/img/切图4.0/头部配件.png") no-repeat;
  background-size: 100%;
  position: relative;
  top: 50px;
  left: 14%;
}
.title {
  // margin-top: 30px;
  margin-bottom: 6px;
  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  span:nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    margin-right: 8px;
    margin-left: 4px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #08f4da;
    line-height: 22px;
  }
}
.header {
  width: 90%;
  height: 36px;
  display: flex;
  background: linear-gradient(
    125deg,
    rgba(19, 250, 239, 0.4) 0%,
    rgba(8, 244, 218, 0) 100%
  );
  border-radius: 5px;
  span:nth-child(1) {
    display: inline-block;
    margin-top: 1.2%;
    margin-left: -0.5%;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
  }
  span:nth-child(2),
  span:nth-child(3),
  span:nth-child(4) {
    text-align: center;

    flex: 1;
    height: 22px;
    font-size: 16px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    line-height: 35px;
    vertical-align: bottom;
  }
  span:nth-child(2) {
    flex: 2;
  }
  span:nth-child(3) {
    flex: 8;
  }
  span:nth-child(4) {
    flex: 1.6;
  }
  span:nth-child(5) {
    display: inline-block;
    margin-top: 1.2%;
    margin-left: -0.5%;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
    margin-left: 3.5%;
  }
}
.realTime {
  height: 350px;
  overflow: hidden;
}
.title1 {
  margin-top: 10px;
  margin-bottom: 6px;
  margin-left: 2%;
  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  span:nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    margin-right: 8px;
    margin-left: 4px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #08f4da;
    line-height: 22px;
  }
}
.header1 {
  margin-left: 2%;

  width: 609px;
  height: 36px;
  background: linear-gradient(
    125deg,
    rgba(19, 250, 239, 0.4) 0%,
    rgba(8, 244, 218, 0) 100%
  );
  border-radius: 5px;
  span:nth-child(1) {
    display: inline-block;
    margin-top: 1.2%;
    margin-left: -0.5%;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
  }
  span:nth-child(2),
  span:nth-child(3) {
    width: 64px;
    height: 22px;
    font-size: 16px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    line-height: 22px;
    vertical-align: bottom;
  }
  span:nth-child(2) {
    margin-left: 3%;
  }
  span:nth-child(3) {
    margin-left: 67%;
  }
  span:nth-child(4) {
    display: inline-block;
    margin-top: 1.2%;
    margin-left: -0.5%;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
    margin-left: 3.5%;
  }
}
.kaijizhanbi {
  height: 150px;
  overflow: hidden;
}
.title2 {
  margin-left: 3%;

  margin-top: 20px;
  margin-bottom: 6px;
  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  span:nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    margin-right: 8px;
    margin-left: 4px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #08f4da;
    line-height: 22px;
  }
}
.header2 {
  margin-left: 3%;
  display: flex;
  width: 569px;
  height: 36px;
  background: linear-gradient(
    125deg,
    rgba(19, 250, 239, 0.4) 0%,
    rgba(8, 244, 218, 0) 100%
  );
  border-radius: 5px;
  span:nth-child(1) {
    display: inline-block;
    margin-top: 1.2%;
    margin-left: -0.5%;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
  }
  span:nth-child(2),
  span:nth-child(3),
  span:nth-child(4) {
    flex: 1;
    width: 64px;
    height: 22px;
    font-size: 16px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
  }
  span:nth-child(3),
  span:nth-child(4) {
    flex: 1.7;
  }
  span:nth-child(5) {
    display: inline-block;
    position: absolute;
    right: 60px;
    margin-top: 1.2%;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
  }
}
.useprogress {
  height: 160px;
  overflow: hidden;
}
.all {
  box-sizing: border-box;
  position: relative;
  top: -2%;
  ::v-deep .el-carousel {
    width: 136%;
    margin-left: -20%;

    position: relative;
    bottom: 240px;
  }
  .content {
    width: 108%;
    background: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: space-evenly;

    .one {
      flex: 0.12;
      text-align: center;
      position: relative;
      right: 3%;
      i {
        width: 59px;
        height: 62px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-style: normal;
        font-weight: 500;
        color: #ffffff;
        line-height: 37px;
        position: relative;
        top: -19%;
        left: -5%;
      }
      .wei {
        color: #7e7e7e;
      }
      span {
        display: block;
        width: 114px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 22px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        top: -12%;
        left: -5%;
      }
      .weii {
        color: #7e7e7e;
      }
    }
  }
}
.titles {
  width: 135%;
  background: url("../../assets/img/切图4.0/底部框.png") no-repeat;
  background-size: 100%;
  height: 100px;
  position: relative;
  bottom: 180px;
  right: 19%;
}

.headers {
  width: 100%;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 22px;
  position: relative;
  bottom: 260px;
  text-align: start;
  background: rgba(0, 0, 0, 0);
  padding-left: 28%;
}

.allm {
  margin-top: -60px;
  // overflow: hidden;
  margin-left: 20px;
  width: 85%;
  .titlem {
    margin-top: 25px;
    margin-bottom: 20px;
    width: 100%;
    span:nth-child(1) {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      border: 2px solid #08f4da;
    }
    span:nth-child(2) {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 22px;
      margin-right: 8px;
      margin-left: 4px;
    }
    span:nth-child(3) {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #08f4da;
      line-height: 22px;
    }
  }
  .content {
    width: 100%;
    display: flex;
    height: 260px;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0);
  }
  ::v-deep .el-carousel {
    height: 160px;
  }
  ::v-deep .el-carousel__button {
    display: none;
  }
  ::v-deep .el-carousel__container {
    height: 130px !important;
  }
  .one {
    flex: 0.3;
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 290px;

    .img {
      background: url("../../assets/img/切图4.0.1/编组 4备份 3.png") no-repeat;
      background-size: 100%;
      height: 160px;
      position: relative;
      bottom: 50px;
    }
    span {
      display: inline-block;
      margin-bottom: -10px;
    }
    span:nth-child(1) {
      font-size: 48px;
      font-family: DS-Digital-BoldItalic, DS-Digital;
      font-weight: normal;
      color: #9365f6;
      line-height: 48px;
      // span:nth-child(1) {
      //   font-size: 17px;
      //   font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      //   font-weight: normal;
      //   color: #9365f6;
      //   position: relative;
      //   bottom: 10%;
      // // }
      i {
        font-style: normal;
        font-size: 17px;
        font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
        font-weight: normal;
        color: #9365f6;
        position: relative;
        bottom: 10%;
      }
      span {
        display: inline-block;
        width: 114px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 22px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 20px;
      }
    }
  }
}
.titlem {
  margin-top: 59px;
  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  // span:nth-child(2) {
  //   font-size: 16px;
  //   font-family: PingFangSC-Medium, PingFang SC;
  //   font-weight: 500;
  //   color: #ffffff;
  //   line-height: 22px;
  //   margin-right: 8px;
  //   margin-left: 4px;
  // }
  // span:nth-child(3) {
  //   font-size: 16px;
  //   font-family: PingFangSC-Semibold, PingFang SC;
  //   font-weight: 600;
  //   color: #08f4da;
  //   line-height: 22px;
  // }
}
.hesuanTime {
  padding-top: 20px;
  width: 628px;
  height: 235px;
  margin-left: -90px;
  transform: translateY(-180px);
  background: url("~@/assets/img/4.0/centerBottom.png") no-repeat;
  background-size: 100% 100%;
  .hesuanTimeTitle {
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
  .nameBox {
    width: 110px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
