<template>
  <div>
    <div class="all">
      <div class="content" :style="{'--uploadStyle':length+'s','--animation':animation}">
        <div v-for="item in list" :key="item">
          <p> <span>{{item?.defendPeriod}}</span> {{' : '+item?.greenNum}}h</p>
          <el-progress stroke-width="6" :percentage="item.percentage">{{item?.pro}}</el-progress>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:"realTime",
  props: {
    //数据
    list: [],
    //动画是否停止
    animation: String,
    //数据长度
    length: Number,
  },
  data () {
    return {

    }
  },
  created () {
  
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
// TODO:滚动问题

.content:hover {
  animation-play-state: paused;
}

.content {
  animation: move var(--uploadStyle) linear infinite;
  animation-play-state: var(--animation);

  width: 92%;
  background: rgba(0, 0, 0, 0);
  padding-left: 26%;
  p {
    text-align: justify;
    position: relative;

    top: 24px;
    left: -33%;
    width: 214px;
    font-size: 16px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    line-height: 22px;
    span {
      display: inline-block;
      width: 114px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
    }
  }
  ::v-deep .el-progress-bar__inner {
    background: linear-gradient(270deg, #f7da65 0%, #07ba74 100%);
  }
  ::v-deep .el-progress-bar__outer {
    margin-right: 20%;
    margin-left: 13%;
    background: rgba(255, 255, 255, 0.2);
  }
  ::v-deep .el-progress__text {
    width: 40px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 25px;
    position: relative;
    right: 12%;
  }
}
/* 通过CSS3动画滚动marquee */
@keyframes move {
  0% {
  }

  100% {
    transform: translateY(-50%);
  }
}
</style>