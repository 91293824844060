<template>
  <div class="all">
    <div class="title">
      <span></span>
      <span>消毒机保护时长</span>
      <span>{{ename}}</span>
    </div>
    <div class="content">
      <div class="one">
        <el-progress type="circle" stroke-width="6" color="#08F4DA" width="100" :percentage="list4.greenNum">
          {{list4.greenNum}}<br />{{'h'}}
        </el-progress>
        <div class="img">累计开机总时长</div>
      </div>
      <div class="two">
        <el-progress type="circle" stroke-width="6" color="#3AF29C" width="100" :percentage="percentage1">
          {{list4.yellowNum}}<br />{{'h'}}
        </el-progress>
        <div class="img">等离子体消毒机</div>

      </div>
      <div class="three">
        <el-progress type="circle" stroke-width="6" color="#5DAEF3" width="100" :percentage="percentage2">
          {{list4.redNum}}<br />{{'h'}}
        </el-progress>
        <div class="img">水离子消毒机</div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    list4: Object,
    percentage1: Number,
    percentage2: Number
  },
  data () {
    return {
      //标题
      ename: 'Disinfection machine protection duration'.toUpperCase(),



    }
  },

 
}
</script>

<style lang="scss" scoped>
.all {
  .title {
    span:nth-child(1) {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      border: 2px solid #08f4da;
    }
    span:nth-child(2) {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 22px;
      margin-right: 8px;
      margin-left: 4px;
    }
    span:nth-child(3) {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #08f4da;
      line-height: 22px;
    }
  }
  .content {
    width: 80%;
    display: flex;
    padding-top: 40px;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0);
    .one {
      flex: 1;

      text-align: center;

      .img {
        width: 120px;
        height: 30px;
        margin-left: 15%;
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #3ac8b7;
        line-height: 22px;
        background: url("~@/assets/img/切图4.0/编组 18.png") no-repeat;
        background-size: 100%;
      }
      ::v-deep .el-progress__text {
        text-align: center;
        font-size: 20px !important;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #08f4da;
        line-height: 20px;
        text-shadow: 0px 0px 7px rgba(8, 244, 218, 0.64);
      }
    }

    .two {
      flex: 1;

      text-align: center;

      .img {
        width: 120px;
        height: 30px;
        margin-left: 15%;
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;

        color: #1dbd72;
        line-height: 22px;
        background: url("~@/assets/img/切图4.0/编组 17.png") no-repeat;
        background-size: 100%;
      }
      ::v-deep .el-progress__text {
        text-align: center;

        font-size: 20px !important;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #3af29c;
        line-height: 24px;
        text-shadow: 0px 0px 5px rgba(29, 189, 114, 0.55);
      }
    }
    .three {
      flex: 1;

      text-align: center;

      .img {
        margin-left: 15%;
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;

        color: #3890dc;
        line-height: 22px;
        background: url("~@/assets/img/切图4.0/编组 5.png") no-repeat;
        background-size: 100%;
        width: 120px;
        height: 30px;
      }
      ::v-deep .el-progress__text {
        text-align: center;

        font-size: 20px !important;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #5daef3;
        line-height: 24px;
        text-shadow: 0px 0px 5px rgba(84, 170, 245, 0.54);
      }
    }
  }
}
</style>
